import React from 'react';
import { Flex, Text, Heading } from '@chakra-ui/core';
import Helmet from 'react-helmet';
import { Button } from '@zeta';
import ContentContainer from '../components/ContentContainer';
import { APP_CONTENT_WIDE_WIDTH } from '../styles/sizes';
import Layout from '../components/layout';

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Zeta's Press Page | Zeta</title>
        <link rel="canonical" href="https://www.askzeta.com/press" />
        <meta
          name="description"
          content="Have questions? We've got answers, logos, and other helpful resources for the press."
        />
        <meta
          property="og:description"
          content="Have questions? We've got answers, logos, and other helpful resources for the press."
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta
          name="keywords"
          content="personal finance, banking, joint banking, joint account, couples finance, family finance, love and money, combining finances, sharing finances"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Zeta | Master Money as a Team" />
        <meta property="og:site_name" content="Zeta | Master Money as a Team" />
        <meta property="og:url" content="https://www.askzeta.com/petsofzeta" />
        <meta property="og:type" content="page" />
        <meta
          property="og:image"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
      </Helmet>
      <Layout mode="home">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <Flex direction="column">
            <Flex
              justifyContent="flex-start"
              direction="column"
              pt={[32, 32]}
              px={[6, 12]}
              style={{ flexGrow: 1, minHeight: '600px' }}
            >
              <Heading fontSize="5xl" as="h1">
                Press &amp; Media
              </Heading>
              <div style={{ height: '1rem' }} />
              <Text fontSize="xl">
                The fastest way to get in touch with us is via email.
              </Text>
              <div style={{ height: '4rem' }} />
              <Button
                text="Email Press Team"
                variant="greenPea"
                onClick={() => {
                  window.location.href = 'mailto:press@askzeta.com';
                }}
              />
            </Flex>
          </Flex>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default Index;
